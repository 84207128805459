import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import SignInButtons from '@/components/app/SignInButtons';
import LayoutWrapperHorizontal from '@/components/component-library/LayoutWrapperHorizontal';
import LogoIcon from '@/components/component-library/Icon/types/Logo';
import Text, { FONT_SIZE_120 } from '@/components/component-library/Text';
import { PRIVACY_POLICY_KEY } from '@/config/routeKeys';
import { classes } from '@/helpers/styling';
import React from 'react';

const BodyText = ({ ...props }) => (
  <Text
    bold
    className={ styles.bodyText }
    size={ FONT_SIZE_120 }
    useHeaderFont
    {...props}
  />
);

const SignInModalMobile = ({ onClickSkip, open }) => {
  return (
    <LayoutWrapperHorizontal
      className={classes(styles.loginContainer, { [styles.isOpen]: open })}
      disableSmallMobileMode
      fillHeight
    >
      <div className={styles.topSection}>
        <LogoIcon className={styles.logo} />
        <div className={styles.bodyTextWrapper}>
          <BodyText>
            We are Keakie
          </BodyText>
          <BodyText>
            Handpicked
          </BodyText>
          <BodyText>
            Try something new
          </BodyText>
        </div>
      </div>

      <div className={styles.bottomSection}>
        <SignInButtons onClickSkip={onClickSkip} />

        <Text className={styles.privacyPolicy}>
          <a href={PRIVACY_POLICY_KEY}>
            Privacy Policy
          </a>
        </Text>
      </div>
    </LayoutWrapperHorizontal>
  );
};

SignInModalMobile.propTypes = {
  onClickSkip: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

SignInModalMobile.defaultProps = { open: false };

export default SignInModalMobile;
