import PropTypes from 'prop-types';
import SignInPageDesktop from './desktop';
import SignInPageMobile from './mobile';
import useShouldRenderForDesktop from '@/hooks/layout/useShouldRenderForDesktop';
import useShouldRenderForMobile from '@/hooks/layout/useShouldRenderForMobile';
import Modal from '@/components/component-library/Modal';

const SignInModal = ({ onClickSkip, open }) => {
  const shouldRenderForMobile = useShouldRenderForMobile();
  const shouldRenderForDesktop = useShouldRenderForDesktop();

  return (
    <Modal id='sign-in' onClick={ onClickSkip } open={ open }>
      { !!shouldRenderForDesktop && <SignInPageDesktop onClickSkip={ onClickSkip } /> }
      { !!shouldRenderForMobile && <SignInPageMobile onClickSkip={ onClickSkip } /> }
    </Modal>
  );
};

SignInModal.propTypes = {
  onClickSkip: PropTypes.func.isRequired,
  open: PropTypes.bool
};

SignInModal.defaultProps = { open: false };

export default SignInModal;