import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import SignInButtons from '@/components/app/SignInButtons';
import LayoutWrapperHorizontal from '@/components/component-library/LayoutWrapperHorizontal';
import LogoIcon from '@/components/component-library/Icon/types/Logo';
import Text, { FONT_SIZE_120 } from '@/components/component-library/Text';
import { PRIVACY_POLICY_KEY } from '@/config/routeKeys';
import { classes } from '@/helpers/styling';
import LinkInTranslation from '@/components/component-library/Link/LinkInTranslation';

const BodyText = ({ ...props }) => (
  <Text bold className={styles.bodyText} size={FONT_SIZE_120} useHeaderFont {...props} />
);

const SignInModalDesktop = ({ onClickSkip, open }) => {
  return (
    <LayoutWrapperHorizontal className={classes(styles.loginContainer, { [styles.isOpen]: open })} fillHeight>
      <div className={styles.topSection}>
        <LogoIcon className={styles.logo} />
        <div className={styles.bodyTextWrapper}>
          <BodyText>
            We are Keakie.
          </BodyText>
          <BodyText>
            Handpicked.
          </BodyText>
          <BodyText>
            Try something new.
          </BodyText>
        </div>
      </div>

      <div className={styles.bottomSection}>
        <SignInButtons onClickSkip={onClickSkip} />

        <Text className={styles.privacyPolicyText}>
          <span className={styles.privacyPolicyLink} href={PRIVACY_POLICY_KEY}>
            Privacy Policy
          </span>
        </Text>
      </div>
    </LayoutWrapperHorizontal>
  );
};

SignInModalDesktop.propTypes = {
  onClickSkip: PropTypes.func.isRequired,
  open: PropTypes.bool
};

SignInModalDesktop.defaultProps = { open: false };

export default SignInModalDesktop;
