import PropTypes from 'prop-types';
import Link from './Link';

const LinkInTranslation = ({ children, ...props }) => (
  <Link {...props}>
    {children || 'Default Link Text'}
  </Link>
);

LinkInTranslation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

LinkInTranslation.defaultProps = {
  children: undefined
};

export default LinkInTranslation;
