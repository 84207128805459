import React, { useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import CONFIG from '@/config/global';
import FacebookIcon from '@/components/component-library/Icon/types/Facebook';
import AppleIcon from '@/components/component-library/Icon/types/Apple';
import LoginContext from '@/contexts/login';
import Text from '@/components/component-library/Text';
import { classes } from '@/helpers/styling';
import useUser from '@/hooks/api/useUser';
import Span from '@/components/component-library/Span';

const SignInButtons = ({ onClickButton, onClickSkip }) => {
  const {
    onFacebookLogin,
    onAppleLogin,
    initialiseAppleSDK,
    isLoggingIn,
    isLoggingOut
  } = useContext(LoginContext);

  const { isLoggedInWithProvider } = useUser();

  const handleClickFacebook = useCallback(() => {
    if (onClickButton) {
      onClickButton();
    }

    onFacebookLogin();
  }, [onFacebookLogin, onClickButton]);

  const handleClickApple = useCallback(() => {
    if (onClickButton) {
      onClickButton();
    }

    onAppleLogin();
  }, [onAppleLogin, onClickButton]);

  const handleSkipClickLogin = useCallback(() => {
    if (onClickButton) {
      onClickButton();
    }

    if (onClickSkip) {
      onClickSkip();
    }
  }, [onClickSkip, onClickButton]);

  useEffect(() => {
    initialiseAppleSDK();
  }, [initialiseAppleSDK]);

  useEffect(() => {}, [isLoggedInWithProvider]);

  const disableButtons = isLoggingIn || isLoggingOut;

  return (
    <>
      <div
        className={ classes(styles.facebookButton, {
          [styles.isDisabled]: disableButtons,
          [styles.disableMargin]: !CONFIG.enableAppleLogin
        }) }
        onClick={ disableButtons ? undefined : handleClickFacebook }
      >
        <Span className={ styles.buttonIconWrapper }>
          <FacebookIcon />
        </Span>
        <Text
          bold className={ styles.buttonText }
          useHeaderFont
        >
          Sign in with Facebook
        </Text>
      </div>
      { CONFIG.enableAppleLogin && (
        <div
          className={ classes(styles.appleButton, {
            [styles.isDisabled]: disableButtons,
            [styles.disableMargin]: true
          }) }
          onClick={ disableButtons ? undefined : handleClickApple }
        >
          <Span className={ styles.buttonIconWrapper }>
            <AppleIcon />
          </Span>
          <Text
            bold className={ styles.buttonText }
            useHeaderFont
          >
            Sign in with Apple
          </Text>
        </div>
      ) }
      { !!onClickSkip && (
        <Text className={ styles.skipLoginForNowText } onClick={ handleSkipClickLogin }>
          Skip sign-in for now
        </Text>
      ) }
    </>
  );
};

SignInButtons.propTypes = {
  onClickButton: PropTypes.func,
  onClickSkip: PropTypes.func
};

SignInButtons.defaultProps = {
  onClickButton: undefined,
  onClickSkip: undefined
};

export default SignInButtons;
