import {
  iconCommonProps, iconDefaultProps, iconPropTypes
} from '../propTypes';

const Facebook = ({ className, color }) => (
  <svg
    className={ className }
    { ...iconCommonProps }
    viewBox='0 0 26 25'
  >
    <path
      d='M25.675 12.538c0-6.904-5.597-12.5-12.5-12.5-6.904 0-12.5 5.596-12.5 12.5 0 6.239 4.57 11.41 10.547 12.348v-8.735H8.048v-3.613h3.174V9.784c0-3.133 1.866-4.863 4.721-4.863 1.368 0 2.798.244 2.798.244V8.24h-1.576c-1.553 0-2.037.964-2.037 1.952v2.345h3.467l-.554 3.613h-2.913v8.735c5.976-.938 10.547-6.109 10.547-12.348z'
      fill={ color }
    />
  </svg>
);

Facebook.propTypes = { ...iconPropTypes };

Facebook.defaultProps = { ...iconDefaultProps };

export default Facebook;